import { Center, CircularProgress, SimpleGrid, VStack } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import React from 'react';

import {
  ActivityApi,
  ActivityDetailResponse,
  ApiConfiguration,
} from '../../apis';
import Layout from '../../components/Layout';
import {
  WLBox,
  WLBreadcrumb,
  WLContainer,
  WLH1,
  WLLink,
  WLText,
} from '../../design-library';
import { formatPhoneNumber } from '../../utils/formatting.utils';

interface ActivityDetailsPageProps extends PageProps<undefined> {}

const ActivityDetailsPage: React.FC<ActivityDetailsPageProps> = ({
  params,
  location,
}) => {
  const activityId = params.activityId!;

  const [data, setData] = React.useState<
    'loading' | 'error' | ActivityDetailResponse
  >('loading');

  React.useEffect(() => {
    if (!activityId) {
      setData('error');
      return;
    }

    setData('loading');
    const api = new ActivityApi(
      new ApiConfiguration({
        basePath: process.env.GATSBY_PUBLIC_API_PATH,
      })
    );
    api
      .activityPost({
        singleActivityRequest: {
          activityId: Number(activityId),
        },
      })
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setData('error');
      });
  }, [activityId]);

  let content;
  if (data === 'error') {
    content = (
      <WLText>
        We were unable to load details for the specified activity, please try
        again later.
      </WLText>
    );
  } else if (data === 'loading') {
    content = (
      <Center width="full">
        <WLBox>
          <CircularProgress isIndeterminate color="blue.800" size="150px" />
          <WLText>Loading details...</WLText>
        </WLBox>
      </Center>
    );
  } else {
    content = (
      <>
        <WLH1>{data.name}</WLH1>

        <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={4} rowGap={4}>
          {data.startDate && (
            <>
              <WLText as="b">Date</WLText>
              <WLText>
                {new Date(data.startDate).toLocaleDateString('en-US')}
              </WLText>
            </>
          )}
          {data.startTime && (
            <>
              <WLText as="b">Time</WLText>
              <WLText>{data.startTime}</WLText>
            </>
          )}
          {data.address && (
            <>
              <WLText as="b">Location</WLText>
              <VStack align="start">
                <WLText>{data.address}</WLText>
                <WLText>
                  {data.city}, {data.state} {data.zipCode}
                </WLText>
              </VStack>
            </>
          )}
          {data.description && (
            <>
              <WLText as="b">Details</WLText>
              <WLText dangerouslySetInnerHTML={{ __html: data.description }} />
            </>
          )}
          {data.fee && (
            <>
              <WLText as="b">Fee</WLText>
              <WLText>{data.fee}</WLText>
            </>
          )}
          {data.registration && (
            <>
              <WLText as="b">Registration URL</WLText>
              <WLBox alignItems="start">
                <WLLink to={data.registration}>Register Here</WLLink>
              </WLBox>
            </>
          )}
          {data.deadline && (
            <>
              <WLText as="b">Registration Deadline</WLText>
              <WLText>
                {new Date(data.deadline).toLocaleDateString('en-US')}
              </WLText>
            </>
          )}
          {data.contact && (
            <>
              <WLText as="b">Contact</WLText>
              <VStack align="start">
                <WLText>{data.contact}</WLText>
                {data.phone && (
                  <WLLink to={`tel:${data.phone}`}>
                    {formatPhoneNumber(data.phone)}
                  </WLLink>
                )}
                {data.email && (
                  <WLLink to={`mailto:${data.email}`}>{data.email}</WLLink>
                )}
              </VStack>
            </>
          )}
          {data.directions && (
            <>
              <WLText as="b">Directions</WLText>
              <WLText>{data.directions}</WLText>
            </>
          )}
        </SimpleGrid>
      </>
    );
  }

  return (
    <Layout
      seo={{
        title: 'Activity Details',
        description: 'Activity Details',
        slug: location.pathname || undefined,
      }}
      currentPath={location.pathname}
    >
      <WLContainer
        width="EXTRA_WIDE"
        align="start"
        paddingX={{ base: 4, lg: 8 }}
        paddingTop={{ base: 2, lg: 2 }}
        paddingBottom={{ base: 3, lg: 3 }}
      >
        <WLBreadcrumb
          entries={[
            { label: 'Home', link: '/' },
            { label: 'Find Activity', link: '/find/activity/' },
            { label: 'Activity Detail' },
          ]}
        />
      </WLContainer>
      <WLContainer width="NARROW" paddingY={8} paddingX={{ base: 2, md: 0 }}>
        {content}
      </WLContainer>
    </Layout>
  );
};

export default ActivityDetailsPage;
